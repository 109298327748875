import { t } from '@lingui/macro'

export const enum downloadAppType {
  ios = 'ios',
  android = 'android',
  h5 = 'h5',
  google = 'google',
  appstore = 'appstore',
}

export const enum downloadDesktopType {
  windows = 'windows',
  mac = 'mac',
  linux = 'linux',
}

export const downloadTypeRanking = {
  [downloadAppType.appstore]: 1,
  [downloadAppType.ios]: 2,
  [downloadAppType.android]: 3,
  [downloadAppType.google]: 4,
  [downloadDesktopType.mac]: 5,
  [downloadDesktopType.windows]: 6,
  [downloadDesktopType.linux]: 7,
}

export const downloadIconsType = {
  [downloadAppType.ios]: 'download_page_apple',
  [downloadAppType.android]: 'download_page_android',
  [downloadAppType.google]: 'page_download_google',
  [downloadAppType.appstore]: 'download_page_apple',

  [downloadDesktopType.windows]: 'download_windows',
  [downloadDesktopType.linux]: 'download_linux',
  [downloadDesktopType.mac]: 'download_page_apple',
}

export const downloadIconsTypeHomePage = {
  [downloadAppType.ios]: 'download_apple',
  [downloadAppType.android]: 'download_android',
  [downloadAppType.google]: 'download_google',
  [downloadAppType.appstore]: 'download_apple',
}

export enum downloadAppStatus {
  active = 1,
  inActive = 2,
}

export type MemberMemberPhoneAreaType = {
  codeVal: string
  enableInd?: number
  codeKey: string
  remark: string
  filterNum?: number
}

export enum MemberMemberPivacyType {
  /** 隐私政策 */
  privacy_policy = 'privacy_policy',
  /** 服务条款 */
  terms_service = 'terms_service',
}

export enum ColorEnumEnum {
  /** 白色 */
  white = 'white',
  /** 黑色 */
  black = 'black',
}
